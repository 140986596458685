import React, {useRef} from 'react';
import Header from '../layout/Header';
import HeroSection from '../components/HeroSection';
import GetStarted from '../components/GetStarted';
import Testimonal from '../components/Testimonals';
import Footer from '../layout/Footer';

const Home: React.FC = () => {
    const getStartedRef = useRef<HTMLDivElement>(null);

    const scrollToGetStarted = () => {
        if (getStartedRef.current) {
            getStartedRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <>
             <Header scrollToGetStarted={scrollToGetStarted} isbutton={true} />
            <HeroSection />
            <div ref={getStartedRef}>
                <GetStarted />
            </div>
            <Testimonal />
        </>
    )
}

export default Home;