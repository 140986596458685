import React from 'react';
import '../stylesheet/heroSection.css';

const HeroSection: React.FC = () => {
    return (
        <section className="hero-section">
            <div className='background p-4 d-flex align-items-center justify-content-center text-center flex-column'>
                <h1 className='text-white mb-3'>Upgrade your Style with AI</h1>
                <p className='text-white'>Discover that perfect outfit for any occasion with FiCheckAI.</p>
            </div>
        </section>
    );
}

export default HeroSection;
