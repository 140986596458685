import React from 'react';
import Header from '../layout/Header';
import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <Header />
            <div className='container' style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333' }}>
                <h2 style={{ textAlign: 'center', color: '#2c3e50' }}>Privacy Policy</h2>
                <p style={{ fontSize: '16px' }}>Effective Date: 01 August 2024</p>
                
                <p style={{ fontSize: '16px' }}>
                    AI Fitcheck ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI Fitcheck App ("the App").
                </p>
                
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>1. Information We Collect</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and demographic information when you register for the App.</li>
                    <li><strong>Uploaded Content:</strong> The App allows you to upload images to receive feedback on your clothing style. These images, along with any feedback generated, are stored on our servers.</li>
                    <li><strong>Usage Data:</strong> We collect information about your usage of the App, including your interactions, preferences, and how you engage with the features.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>2. How We Use Your Information</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Service Delivery:</strong> To provide personalized feedback on your clothing style.</li>
                    <li><strong>Improvement:</strong> To analyze and improve the performance of the App.</li>
                    <li><strong>Communication:</strong> To communicate with you regarding updates, offers, and other relevant information.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>3. Sharing Your Information</h3>
                <p style={{ fontSize: '16px' }}>
                    We do not sell, trade, or otherwise transfer your personal information to third parties, except for the following:
                </p>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist in the operation of the App.</li>
                    <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect the rights, property, or safety of our users or the public.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>4. Security</h3>
                <p style={{ fontSize: '16px' }}>
                    We implement industry-standard security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure.
                </p>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>5. Your Choices</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Access and Correction:</strong> You may access and update your personal information within the App.</li>
                    <li><strong>Opt-Out:</strong> You may opt out of receiving promotional communications by following the unsubscribe instructions in those communications.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>6. Children's Privacy</h3>
                <p style={{ fontSize: '16px' }}>
                    The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
                </p>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>7. Changes to this Privacy Policy</h3>
                <p style={{ fontSize: '16px' }}>
                    We may update this Privacy Policy from time to time. We will notify you of any significant changes by updating the "Effective Date" at the top of this document.
                </p>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>8. Contact Us</h3>
                <p style={{ fontSize: '16px' }}>
                    If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:fitcheckai.app@gmail.com">fitcheckai.app@gmail.com</a>.
                </p>
            </div>
        </>
    );
}

export default PrivacyPolicy;
