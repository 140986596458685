import React from 'react';
import Router from './Router/Router';

function App() {
  return (
   <Router />
  );
}

export default App;
