import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import '../stylesheet/card.css'

const Testimonal: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <section className='testimonal mt-4 p-4' style={{backgroundColor: ' rgb(239,223,226, 0.3) '}}>
            <div className='container'>
                <h1 className='text-center' style={{ color: '#535CE8' }}>Testimonals</h1>
                <div className='mt-3'>
                    <div className='testimonals d-flex align-items-start justify-content-start gap-3 bg-white p-3' style={{ borderRadius: '8px' }}>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&s" style={{ height: '60px', width: '60px', borderRadius: '50%', objectFit: 'cover' }} alt="user-image" />
                        <div>
                            <p style={{ margin: '0px', fontSize: '20px', fontWeight: 700 }}>Alex Johnson</p>
                            <p style={{ margin: '0px', fontSize: '16px', fontWeight: 500 }}>"FitCheckAI has completely transformd my wardrobe. The Feedback is spot on and has helped me look my best every day."</p>
                        </div>
                    </div>

                    <div className='testimonals d-flex align-items-start justify-content-start gap-3 bg-white p-3 mt-2' style={{ borderRadius: '8px', marginLeft: isMobile ? '0px': '150px' }}>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&s" style={{ height: '60px', width: '60px', borderRadius: '50%', objectFit: 'cover' }} alt="user-image" />
                        <div>
                            <p style={{ margin: '0px', fontSize: '20px', fontWeight: 700 }}>Michal Scott</p>
                            <p style={{ margin: '0px', fontSize: '16px', fontWeight: 500 }}>"I love how easy it is to use FitCheckAI. The AI Feedback is incredibly accurate, and i've received so many compliments on my outfits!."</p>
                        </div>
                    </div>

                    <div className='testimonals d-flex align-items-start justify-content-start gap-3 bg-white p-3 mt-2' style={{ borderRadius: '8px' }}>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQEZrATmgHOi5ls0YCCQBTkocia_atSw0X-Q&s" style={{ height: '60px', width: '60px', borderRadius: '50%', objectFit: 'cover' }} alt="user-image" />
                        <div>
                            <p style={{ margin: '0px', fontSize: '20px', fontWeight: 700 }}>Smantha Lee</p>
                            <p style={{ margin: '0px', fontSize: '16px', fontWeight: 500 }}>"FitCheckAi is truly a game-changer. I can quickly get feedback on my outfits and make adjustment to loook my best. Highly recommanded!."</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonal;