import React, { useState } from 'react';
import Header from '../layout/Header';




const DeleteAccountForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleDeleteAccount = async (event: React.FormEvent) => {
        event.preventDefault();
    
        const requestBody = {
            email: email,
            password: password,
        };
    
        try {
            
            const response = await fetch('https://api.fitcheckai.app/device/api/v1/user/delete-user-account', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete account.');
            }
    
            const result = await response.json();
            console.log('Account deletion successful:', result);
            alert('Account deleted successfully!');
            // Show a success toast notification
          
    
        } catch (error) {
            console.error('Error deleting account:', error);
            // Show an error toast notification
              // Show an error alert
        alert('Failed to delete account. Please try again.');
          
        }
    };
    
    
    

    return (
        <>
            <Header />
            <div className='container' style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333' }}>
                <h2 style={{ textAlign: 'center', color: '#e74c3c' }}>Delete Account</h2>
                <p style={{ fontSize: '16px', color: '#e74c3c' }}>
                    Please enter your email and password to confirm the deletion of your account. This action is irreversible.
                </p>

                <div className="form-container" style={{ maxWidth: '400px', margin: '0 auto', display: 'flex', flexDirection: 'column' }}>
                    <form onSubmit={handleDeleteAccount} style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="email" style={{ marginBottom: '10px', fontSize: '16px' }}>Email:</label>
                        <input 
                            type="email" 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                            style={{ padding: '10px', fontSize: '16px', marginBottom: '20px', borderRadius: '4px', border: '1px solid #ccc' }} 
                        />

                        <label htmlFor="password" style={{ marginBottom: '10px', fontSize: '16px' }}>Password:</label>
                        <input 
                            type="password" 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                            style={{ padding: '10px', fontSize: '16px', marginBottom: '20px', borderRadius: '4px', border: '1px solid #ccc' }} 
                        />

                        <button 
                            type="submit" 
                            style={{ padding: '10px', fontSize: '16px', backgroundColor: '#e74c3c', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                        >
                            Delete Account
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default DeleteAccountForm;
