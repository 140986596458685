import React from 'react';
import Header from '../layout/Header';

const TermCondition: React.FC = () => {
    return (
        <>
            <Header />
            <div className='container' style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333' }}>
                <h2 style={{ textAlign: 'center', color: '#2c3e50' }}>Terms and Conditions</h2>
                <p style={{ fontSize: '16px' }}>Effective Date: 01 August 2024</p>

                <p style={{ fontSize: '16px' }}>
                Welcome to <span style={{fontWeight:'bold'}}>AI Fitcheck.</span> These Terms and Conditions ("Terms") govern your use of the AI Fitcheck App ("the App"). By accessing or using the App, you agree to be bound by these Terms.
                </p>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>1. Use of the App</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Eligibility:</strong> You must be at least 13 years old to use the App.</li>
                    <li><strong>Account Creation:</strong> You must provide accurate and complete information when creating an account.</li>
                    <li><strong>Content:</strong> You are responsible for any images or content you upload to the App. You must have the necessary rights to share this content.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>2. User Conduct</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Prohibited Activities:</strong> You agree not to use the App for any unlawful purposes, to upload content that is defamatory, obscene, or infringing on others' rights, or to interfere with the App’s operation.</li>
                    <li><strong>Termination:</strong> We reserve the right to terminate or suspend your access to the App if you violate these Terms.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>3. Intellectual Property</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>Ownership:</strong> All content, features, and functionality of the App are the exclusive property of AI Fitcheck or its licensors.</li>
                    <li><strong>License:</strong> By uploading content, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, and display your content for the operation of the App.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>4. Disclaimer</h3>
                <ul style={{ marginLeft: '20px', fontSize: '16px' }}>
                    <li><strong>No Warranty:</strong> The App is provided "as is" and "as available" without any warranties, express or implied.</li>
                    <li><strong>Limitation of Liability:</strong> In no event will we be liable for any damages arising from your use of the App.</li>
                </ul>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>5. Governing Law</h3>
                <p style={{ fontSize: '16px' }}>
                These Terms are governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts in New Jersey
                </p>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>6. Changes to this Terms</h3>
                <p style={{ fontSize: '16px' }}>
                We may modify these Terms at any time. We will notify you of any changes by updating the "Effective Date" at the top of this document. Your continued use of the App after any such changes constitute your acceptance of the new Terms.
                </p>

                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>7. Contact Us</h3>
                <p style={{ fontSize: '16px' }}>
                    If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:fitcheckai.app@gmail.com">fitcheckai.app@gmail.com</a>.
                </p>
            </div>
        </>
    );
}

export default TermCondition;
