// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
}

.custom-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card-img-custom {
    height: 250px;
    object-fit: contain;
    width: 100%;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.card-title {
    margin-bottom: auto;
}

.card-text {
    margin-top: auto;
}


/* testimonals  */
.testimonals{
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in;
}

.testimonals:hover{
    transform: translateX(30px);
}

/* Get Started */
.scale-img {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.scale-img:hover {
    transform: scale(1.1); 
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2); 
}

@media screen and (max-width: 600px) {
    .testimonals{
        transition: none;
    }
    
    .testimonals:hover{
        transform: none;
    }
}


`, "",{"version":3,"sources":["webpack://./src/stylesheet/card.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,2CAA2C;IAC3C,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;IAC5B,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA,iBAAiB;AACjB;IACI,eAAe;IACf,wCAAwC;IACxC,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA,gBAAgB;AAChB;IACI,mEAAmE;AACvE;;AAEA;IACI,qBAAqB;IACrB,4CAA4C;AAChD;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".custom-card {\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    transition: transform 0.3s, box-shadow 0.3s;\n    display: flex;\n    flex-direction: column;\n}\n\n.custom-card:hover {\n    transform: translateY(-10px);\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);\n}\n\n.card-img-custom {\n    height: 250px;\n    object-fit: contain;\n    width: 100%;\n}\n\n.card-body {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    flex-grow: 1;\n}\n\n.card-title {\n    margin-bottom: auto;\n}\n\n.card-text {\n    margin-top: auto;\n}\n\n\n/* testimonals  */\n.testimonals{\n    cursor: pointer;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    transition: all 0.3s ease-in;\n}\n\n.testimonals:hover{\n    transform: translateX(30px);\n}\n\n/* Get Started */\n.scale-img {\n    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;\n}\n\n.scale-img:hover {\n    transform: scale(1.1); \n    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2); \n}\n\n@media screen and (max-width: 600px) {\n    .testimonals{\n        transition: none;\n    }\n    \n    .testimonals:hover{\n        transform: none;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
