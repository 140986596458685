import React from 'react'
import Logo from '../assets/images/logo.svg'
import { Link } from 'react-router-dom';

interface HeaderProps {
    scrollToGetStarted?: () => void;
    isbutton?: boolean;
}

const Header: React.FC<HeaderProps> = ({ scrollToGetStarted, isbutton }) => {
    return (
        <section className='header bg-white'>
            <div className="container-fluid pb-2 pt-2 p-4">
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center justify-content-center gap-2'>
                        <Link to='/'><img src={Logo} alt="logo" className='img-fluid' style={{ height: '50px', width: '50px', cursor: 'pointer' }} /></Link>
                        <Link to='/'> <p style={{ margin: '0px', cursor: 'pointer', fontWeight: 600 }}>FitCheckAI</p></Link>
                    </div>
                    <div>
                        {isbutton ? (
                            <button style={{ padding: '8px 30px', backgroundColor: '#3232d1', border: 'none', borderRadius: '8px', color: 'white', fontSize: '16px', fontWeight: 600 }} onClick={scrollToGetStarted}>
                                Get Started
                            </button>
                        ) : ''}

                    </div>
                </div>
                <div className="custom-cards">
                    <div className="cutom-card">

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Header;