import React from 'react';
import Logo from '../assets/images/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import '../stylesheet/footer.css';
import { useMediaQuery } from '@mui/material';

const Footer: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    // Social media icons JSX
    const socialMediaIcons = (
        <div className="d-flex align-items-center justify-content-center gap-4" >
            <Link to="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'facebook']}  style={{ color: '#4267B2', fontSize: isMobile ? '13px' : '20px' }} />
            </Link>
            <Link to="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'youtube']}  style={{ color: '#FF0000', fontSize: isMobile ? '13px' : '20px' }} />
            </Link>
            <Link to="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'x-twitter']}  style={{ color: 'black', fontSize: isMobile ? '13px' : '20px' }} />
            </Link>
            <Link to="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'linkedin']}  style={{ color: '#0A66C2', fontSize: isMobile ? '13px' : '20px' }} />
            </Link>
        </div>
    );

    return (
        <section className='footer pt-3' style={{ backgroundColor: 'rgb(82,82,223,0.2)'}}>
            <div className='d-flex align-items-center justify-content-between ps-4 pe-4' style={{ flexDirection: isMobile ? 'column' : 'row' }}>
                <div className='d-flex align-items-center justify-content-center gap-2'>
                    <img src={Logo} alt="LogoImage" style={{ height: isMobile ? '40px' :'50px', width: isMobile ? '40px' : '50px' }} />
                    <h5>FitCheckAI</h5>
                </div>

                {/* Conditionally display social media icons before or after based on screen size */}
                {isMobile && socialMediaIcons}

                <div className='d-flex align-items-center justify-content-center' style={{ flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '5px' : '3rem', marginTop: isMobile ? '5px'  :''  }}>
                    <Link target='_blank' to='/privacy-policy'>Privacy Policy</Link>
                    <Link target='_blank' to='/terms-condition'>Terms of Service</Link>
                    <Link target='_blank' to='/'>Contact Us : fitcheckai.app@gmail.com </Link>
                </div>

                {/* Conditionally display social media icons after for larger screens */}
                {!isMobile && socialMediaIcons}
            </div>
        </section>
    );
};

export default Footer;
